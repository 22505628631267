import { template as template_e6960529cd574e8696bc01ebae7c1cd3 } from "@ember/template-compiler";
const FKLabel = template_e6960529cd574e8696bc01ebae7c1cd3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
