import { template as template_12f4dfad2c5c4683b288f76578d315eb } from "@ember/template-compiler";
const FKText = template_12f4dfad2c5c4683b288f76578d315eb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
